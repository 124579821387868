 /* eslint-disable */

const v2Data = [
  {
      "twitterId": "mmnk_vt",
      "boothNo": "OFFICIAL",
      "boothURL": "https://mmnk-vt.booth.pm/items/2762152",
      "MenuSubmittedAt": "2021/2/6 18:57:52",
      "PosterSubmittedAt": "2021/1/9 16:46:26",
      "place": 0,
      "PDFSubmittedAt": "2021/2/12 17:31:43",
      "displayName": "みみんく",
      "hasPoster": true,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "place": 600700,
      "displayName": "かいぴっぴ",
      "boothURL": "https://kaipi.booth.pm",
      "PosterSubmittedAt": "2021/2/15 0:34:27",
      "twitterId": "higashi_zum428",
      "PDFSubmittedAt": "2021/2/15 4:59:21",
      "MenuSubmittedAt": "2021/2/15 0:34:21",
      "boothNo": "AVATAR",
      "hasPoster": true,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "displayName": "GlinT",
      "PDFSubmittedAt": "2021/1/14 11:49:39",
      "twitterId": "glintfraulein",
      "place": 602900,
      "boothNo": "AVATAR",
      "boothURL": "https://glintfraulein.booth.pm/items/2612867",
      "MenuSubmittedAt": "2021/1/14 12:10:53",
      "hasPoster": false,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "twitterId": "1103tetra",
      "displayName": "テトラ",
      "boothNo": "AVATAR",
      "MenuSubmittedAt": "2021/2/19 0:52:37",
      "PDFSubmittedAt": "2021/2/19 0:53:19",
      "place": 606200,
      "hasPoster": false,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "PosterSubmittedAt": "2021/1/17 16:43:44",
      "twitterId": "kurotori4423",
      "displayName": "黒鳥",
      "MenuSubmittedAt": "2021/1/17 16:43:34",
      "place": 607800,
      "boothNo": "AVATAR",
      "boothURL": "https://kurotori.booth.pm/",
      "hasPoster": true,
      "hasCover": false,
      "hasMenu": true
  },
  {
      "PDFSubmittedAt": "2021/2/14 22:06:55",
      "PosterSubmittedAt": "2021/2/4 11:41:29",
      "twitterId": "lowteq_vr",
      "displayName": "ろーてく",
      "place": 608000,
      "boothNo": "AVATAR",
      "MenuSubmittedAt": "2021/2/10 1:26:17",
      "boothURL": "https://lowteq.booth.pm/",
      "hasPoster": true,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "boothURL": "https://cupofhappiness.booth.pm/",
      "boothNo": "AVATAR",
      "twitterId": "_cupofhappiness",
      "displayName": "イツキ",
      "PDFSubmittedAt": "2021/2/10 15:11:18",
      "PosterSubmittedAt": "2021/2/3 17:34:48",
      "place": 608200,
      "MenuSubmittedAt": "2021/2/3 17:34:40",
      "hasPoster": true,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "boothNo": "AVATAR",
      "place": 608500,
      "MenuSubmittedAt": "2021/2/14 1:34:48",
      "twitterId": "nazeru2",
      "PDFSubmittedAt": "2021/2/14 3:52:52",
      "displayName": "Nazell",
      "boothURL": "https://nazell.booth.pm/",
      "hasPoster": false,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "boothNo": "AVATAR",
      "boothURL": "https://hiyo-kouekijo.booth.pm/",
      "MenuSubmittedAt": "2021/1/30 21:35:34",
      "PDFSubmittedAt": "2021/1/30 21:45:19",
      "place": 609400,
      "twitterId": "hiyo_4tweeteco",
      "PosterSubmittedAt": "2021/2/7 11:09:00",
      "displayName": "ヒヨ",
      "hasPoster": true,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "MenuSubmittedAt": "2021/2/11 20:50:03",
      "place": 609600,
      "boothURL": "https://github.com/mew-ton/teckbook_convert_lowpoly",
      "boothNo": "AVATAR",
      "displayName": "みゅーとん",
      "twitterId": "_mew_ton",
      "PDFSubmittedAt": "2021/2/14 2:19:28",
      "PosterSubmittedAt": "2021/2/9 23:47:32",
      "hasPoster": true,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "boothNo": "AVATAR",
      "displayName": "miyakon",
      "place": 609850,
      "PDFSubmittedAt": "2021/1/16 12:11:06",
      "twitterId": "miyakon2nd",
      "PosterSubmittedAt": "2021/1/16 12:11:24",
      "MenuSubmittedAt": "2021/1/16 12:11:22",
      "boothURL": "https://techbookfest.org/product/5979610120454144?productVariantID=5996744221392896",
      "hasPoster": true,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "twitterId": "clytie_vrchat",
      "boothNo": "EFFECT",
      "PDFSubmittedAt": "2021/2/14 17:55:59",
      "boothURL": "https://clytie.booth.pm/",
      "displayName": "向日葵。",
      "place": 700100,
      "MenuSubmittedAt": "2021/2/14 17:54:54",
      "hasPoster": false,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "place": 700200,
      "MenuSubmittedAt": "2021/1/17 14:24:39",
      "twitterId": "remiiiiiiit",
      "displayName": "れみーと",
      "PosterSubmittedAt": "2021/1/17 14:24:42",
      "boothURL": "https://remiit-workshop.booth.pm/",
      "PDFSubmittedAt": "2021/2/15 5:42:01",
      "boothNo": "EFFECT",
      "hasPoster": true,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "PosterSubmittedAt": "2021/2/10 11:00:35",
      "boothNo": "EFFECT",
      "twitterId": "noriben327",
      "boothURL": "https://noriben.booth.pm/",
      "displayName": "noriben",
      "PDFSubmittedAt": "2021/2/10 11:19:40",
      "place": 700400,
      "MenuSubmittedAt": "2021/2/10 11:00:19",
      "hasPoster": true,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "boothNo": "OTHER",
      "displayName": "Lcamu",
      "PosterSubmittedAt": "2021/2/14 2:02:00",
      "boothURL": "https://lcamu.booth.pm",
      "twitterId": "ogtonvr180426",
      "MenuSubmittedAt": "2021/2/14 22:11:15",
      "place": 800100,
      "PDFSubmittedAt": "2021/2/23 19:53:54",
      "hasPoster": true,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "PosterSubmittedAt": "2021/1/17 18:34:37",
      "MenuSubmittedAt": "2021/2/11 12:50:55",
      "place": 800200,
      "displayName": "A3",
      "boothURL": "https://a3s.booth.pm/items/2666668",
      "boothNo": "OTHER",
      "twitterId": "a3_yuu",
      "PDFSubmittedAt": "2021/2/11 12:51:56",
      "hasPoster": true,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "MenuSubmittedAt": "2021/2/14 23:03:58",
      "place": 800300,
      "displayName": "ヤギハタ",
      "PDFSubmittedAt": "2021/2/14 23:05:28",
      "boothNo": "OTHER",
      "PosterSubmittedAt": "2021/2/14 23:06:11",
      "boothURL": "https://yagihata.booth.pm/",
      "twitterId": "yagihata4x",
      "hasPoster": true,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "PosterSubmittedAt": "2021/1/9 6:11:43",
      "boothURL": "https://nuwaa.booth.pm/items/2668715",
      "twitterId": "nuwaa_vr",
      "displayName": "nuwaa",
      "place": 800500,
      "boothNo": "OTHER",
      "PDFSubmittedAt": "2021/1/18 19:32:02",
      "MenuSubmittedAt": "2021/1/10 1:16:22",
      "hasPoster": true,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "boothNo": "UDON",
      "PosterSubmittedAt": "2021/1/16 22:18:20",
      "twitterId": "makihiro_vr",
      "place": 900200,
      "PDFSubmittedAt": "2021/1/16 22:18:05",
      "displayName": "makihiro",
      "MenuSubmittedAt": "2021/1/16 22:18:08",
      "hasPoster": true,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "place": 900300,
      "displayName": "わた",
      "boothURL": "https://wata-ofuton.booth.pm/",
      "MenuSubmittedAt": "2021/1/17 2:34:39",
      "boothNo": "UDON",
      "twitterId": "wata_pj",
      "PDFSubmittedAt": "2021/2/13 15:19:16",
      "hasPoster": false,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "MenuSubmittedAt": "2021/1/20 23:19:00",
      "PDFSubmittedAt": "2021/2/7 19:21:32",
      "place": 1000050,
      "boothNo": "WORLD",
      "twitterId": "hidetobara",
      "boothURL": "https://hidetobara.booth.pm/",
      "displayName": "みかん農家",
      "hasPoster": false,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "displayName": "まさき43380",
      "place": 1000100,
      "boothURL": "https://kinseilibrary.booth.pm/",
      "MenuSubmittedAt": "2021/1/11 3:00:06",
      "twitterId": "masaki_nire",
      "boothNo": "WORLD",
      "PDFSubmittedAt": "2021/2/13 13:36:40",
      "hasPoster": false,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "twitterId": "torgtaitai",
      "MenuSubmittedAt": "2021/2/14 22:18:12",
      "displayName": "たいたい竹流",
      "PosterSubmittedAt": "2021/2/14 22:18:45",
      "boothURL": "https://torgtaitai.booth.pm/items/2516837",
      "PDFSubmittedAt": "2021/2/14 22:17:59",
      "boothNo": "WORLD",
      "place": 1000150,
      "hasPoster": true,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "boothURL": "https://kanonji.booth.pm/",
      "MenuSubmittedAt": "2021/2/15 0:22:10",
      "displayName": "kanonji",
      "boothNo": "WORLD",
      "place": 1000200,
      "twitterId": "kanonji",
      "PDFSubmittedAt": "2021/2/15 0:21:20",
      "PosterSubmittedAt": "2021/2/15 0:22:27",
      "hasPoster": true,
      "hasCover": true,
      "hasMenu": true
  },
  {
      "twitterId": "otoan010",
      "displayName": "otoan010/おとあん",
      "PDFSubmittedAt": "2021/2/15 3:15:12",
      "place": 1000400,
      "boothNo": "WORLD",
      "MenuSubmittedAt": "2021/2/15 2:51:38",
      "boothURL": "https://drive.google.com/file/d/1KxWe3fH4BzfhugRV6Gz3YD2nCASckjke/view?usp=sharing",
      "hasPoster": false,
      "hasCover": true,
      "hasMenu": true
  }
];

export const getV2Data = () => v2Data;
export default {};
